<template>
  <a-card
    size="small"
    hoverable
    class="h-100 d-flex flex-column"
    style="flex:30%"
    :body-style="{ height: '1px', flex: '1 1 auto', overflowY: 'auto' }"
  >
    <template #title>
      <span>{{ title }}:</span>
      <a-tag class="ml-2" color="processing">
        {{ missedSteps.length }}
      </a-tag>
    </template>
    <a-skeleton
      :loading="isTaskDetailsLoading"
      active
      :paragraph="{ rows: 13 }"
    >
      <ul id="infer-md-missed-step-list" class="list-group">
        <li
          v-for="(item, index) in missedSteps"
          :id="'infer-md-missed-step-li-' + index"
          :key="index"
          class="list-group-item list-group-item-danger"
        >
          {{ stepsTranslationMapping[item] || item }}
        </li>
      </ul>
    </a-skeleton>
  </a-card>
</template>
<script>
export default {
  props: {
    missedSteps: { type: Array, default: () => [] },
    title: { type: String, default: 'Missed steps in the previous cycle' },
    stepsTranslationMapping: { type: Object, default: () => ({}) },
    isTaskDetailsLoading: { type: Boolean, default: false },
  },
};
</script>
<style scoped>
.list-group-item {
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 0.73em;
}
</style>
