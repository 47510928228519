<template>
  <a-card
    size="small"
    hoverable
    class="h-100 d-flex flex-column"
    :body-style="{ height: '1px', flex: '1 1 auto', overflowY: 'auto' }"
  >
    <template #title>
      <a-descriptions :title="null">
        <a-descriptions-item span="3" class="p-0" label="Cycle">
          {{ cycle }}
        </a-descriptions-item>
        <a-descriptions-item
          v-if="taskProcesses?.length === 1"
          span="3"
          class="p-0"
          label="Cycle time"
        >
          {{ getProcessCycleTime(0) }}s
        </a-descriptions-item>
        <a-descriptions-item
          v-for="(process, index) in taskProcesses"
          v-else
          :key="index"
          span="3"
          class="p-0"
          :label="`Cycle time (sub-task ${index + 1})`"
        >
          {{ getProcessCycleTime(index) }}s
        </a-descriptions-item>
        <a-descriptions-item span="3" class="p-0" label="Background time">
          {{ backgroundTime }}s
        </a-descriptions-item>
      </a-descriptions>
    </template>
    <template #extra>
      <a-tag v-if="isPredictionFileNotExist" color="error">
        <template #icon>
          <stop-outlined />
        </template>
        No Predictions
      </a-tag>
    </template>

    <a-skeleton
      :loading="isTaskDetailsLoading"
      active
      :paragraph="{ rows: 13 }"
    >
      <ul id="inference-md-steps-list" class="list-group">
        <li
          v-for="(step, index) in stepsList"
          :id="'inference-md-step-li-' + index"
          :key="index"
          class="list-group-item"
          :class="{
            'cursor-default': missedStepsList.includes(step.name),
            'current-step': currentStepList.includes(step.substepIndex),
            'list-group-item-primary font-weight-bold mt-1':
              step.type === 'step',
            'list-group-item-warning': currentStepList.includes(
              step.substepIndex
            ),
            'list-group-item-success':
              detectedSteps[cycle] &&
              detectedSteps[cycle][step.substepIndex] &&
              !currentStepList.includes(step.substepIndex)
          }"
          @click="onClickStep(step.substepIndex)"
        >
          <span
            :id="'infer-step-li-' + index + '-name'"
            :class="{ 'ml-2': step.type === 'sub-step' }"
          >
            {{ stepsTranslationMapping[step.name] || step.name }}
          </span>
          <span class="ml-auto" />
          <span
            v-if="stepsTime[step.substepIndex] !== undefined"
            :id="'infer-step-li-' + index + '-substep-time'"
            class="mr-2"
          >
            ({{ getTime(step.substepIndex) }})
          </span>
          <span
            v-if="negativeStepsList.includes(step.substepIndex)"
            :id="'infer-step-li-' + index + '-ve-step'"
            class="negative-step"
          >
            Negative step
          </span>

          <span
            v-if="optionalStepsList.includes(step.substepIndex)"
            :id="'infer-step-li-' + index + '-ve-step'"
            class="optional-step"
          >
            Optional step
          </span>
        </li>
      </ul>
    </a-skeleton>
  </a-card>
</template>
<script>
import { StopOutlined } from '@ant-design/icons-vue';
import { mapGetters } from 'vuex';

export default {
  components: { StopOutlined },
  props: {
    stepsList: { type: Array, default: () => [] },
    currentStepList: { type: Array, default: () => [] },
    cycle: {},
    cycleTime: {},
    backgroundTime: {},
    detectedSteps: {},
    missedStepsList: { default: [] },
    negativeStepsList: { default: [] },
    optionalStepsList: { default: [] },
    stepsTime: {},
    isTaskDetailsLoading: {},
    isPredictionFileNotExist: {},
    stepsTranslationMapping: { default: {} },
    processDetails: {},
    isValidation: { default: false }
  },
  emits: ['handleClickSubStep'],

  computed: {
    ...mapGetters(['taskProcesses']),
    getTotalBackgroundTime() {
      return this.backgroundTime;
    }
  },
  methods: {
    getProcessCycleTime(processNum) {
      if (Object.keys(this.processDetails).length === 0) {
        return 0;
      }
      let sum = 0;
      for (
        let i = this.processDetails[processNum][0];
        i <= this.processDetails[processNum][1];
        i++
      ) {
        sum += this.stepsTime[i] || 0;
      }
      if (this.backgroundTime) {
        sum += Number(this.backgroundTime);
      }
      return Number(sum).toFixed(2);
    },

    getTime(stepIndex) {
      return Number(this.stepsTime[stepIndex])?.toFixed(2) + ' s';
    },
    onClickStep(stepIndex) {
      this.$emit('handleClickSubStep', stepIndex);
    }
  }
};
</script>
<style scoped>
.heading {
  margin-bottom: 0.5em;
}
.list-container {
  overflow-y: auto;
  height: 74vh;
}
.current-step {
  font-weight: bold;
  font-size: 0.9em !important;
}
.list-group-item {
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 0.4em;
  font-size: 0.73em;
}
.negative-step {
  color: red;
}

.optional-step {
  color: blue;
}
.cursor-default {
  cursor: default;
}
</style>
