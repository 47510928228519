import { TaskInfoCloud } from '../data_classes/TaskInfo';
import { UserInfoCloud } from '../data_classes/UserInfo';

export class StatisticalLoggerInterface {
  constructor() {
    if (this.constructor === StatisticalLoggerInterface) {
      throw new Error("Abstract classes can't be instantiated.");
    }
    this.cumulatedProcesses = {};
    this.currentTime = null;
    this.previousTime = null;
    this.currentProcess = null;
    this.userInfo = null;
    this.taskInfo = null;
    this.count = 0;
  }

  inferenceStateChanged = () => {};

  setupVars = async (kwargs) => {
    if (kwargs['platform'] === 'cloud') {
      this.userInfo = new UserInfoCloud(kwargs);
      this.taskInfo = new TaskInfoCloud(kwargs['allTasks']);
      await this.taskInfo.init(this.userInfo);
    }
    for (process in this.taskInfo.processes) {
      this.cumulatedProcesses[process.id] = [];
    }
  };

  sendPredToProcess = (kwargs) => {};

  newPredReceived = (predictions, time) => {};

  tryToStartCycle = (prediction, time) => {};

  tryToEndCycle = (prediction, timeDiff, time) => {};

  getCurrentProcess = (prediction) => {
    // todo apply demeter here
    for (let process of this.taskInfo.processes) {
      if (process.subSteps.includes(prediction)) {
        return process;
      }
    }
  };
}
