export default function(text) {
  if (!text) {
    return;
  }
  const text_lines = text?.split('\n');

  const list = [];
  let index = 0;

  for (let line of text_lines) {
    line = line.trim();

    if (line[line.length - 1] == ':') {
      list.push({
        name: line.slice(0, -1),
        type: 'step',
      });
    } else if (line[line.length - 1] == ';') {
      list.push({
        name: line.slice(0, -1),
        type: 'sub-step',
        substepIndex: index,
      });
      index++;
    }
  }

  return list;
}

// export default function(text) {
//   console.log("text", text);
//   const task = {
//     name: "",
//     steps: [],
//   };

//   const text_lines = text.split("\n");

//   if (text_lines.length === 0) {
//     return;
//   }

//   task.name = text_lines[0].split("=")[1];
//   let substeps = [];
//   let step = "";
//   let stepCount = 0;
//   for (let line of text_lines) {
//     line = line.trim();

//     if (line[line.length - 1] == ":") {
//       stepCount++;
//     } else if (line[line.length - 1] == ";") {
//       const obj = {
//         task: line.slice(0, -1) + " #" + stepCount,
//         active: false,
//         isCompleted: false,
//         time: Math.max(2, Math.floor(Math.random() * 7)),
//       };
//       substeps.push(obj);
//     }
//   }
//   return substeps;
// }
